import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
// import { FaBullseye } from 'react-icons/fa';

import Layout from "../components/layout"
import SEO from "../components/seo"

const seoTitle = "Chairman's Message"

const ChairmansMessagePage = ({data}) => (
    <Layout>
        <SEO title={seoTitle} />
        <div className="container">
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            <div className="row article-heading">
                <div className="col">
                    <h3><span className="label label-primary">{data.markupql.edges[0].node.frontmatter.heading}</span></h3>
                </div>
            </div>
            <div className="row article-text">
                <div className="col">
                    <Img fixed={data.peopleql.childImageSharp.fixed} title={data.markupql.edges[0].node.frontmatter.imageTitle} Tag="span"/>
                    <span
                        dangerouslySetInnerHTML={{ __html: data.markupql.edges[0].node.html }}
                    >
                    </span>
                </div>
            </div>
        </div>
    </Layout>
)

export default ChairmansMessagePage

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/chairmans-message-1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/chairmans-message/i"}}) {
        edges {
          node {
            frontmatter {
              heading
              imageTitle
            }
            html
          }
        }
    }

    peopleql: file(relativePath: {eq: "people/manager.jpg"}) {
        childImageSharp {
            fixed(width: 200) {
                ...GatsbyImageSharpFixed
            }
        }
    }
}
`